// Supported feature flags
export const FeatureFlag = [
  "TRAINING_AI__APP__189322",
  "ISEARCH__DATA__IMPORT__197186",
  "CHAT_AI__ASSISTANTSAPI__199004",
  "CHAT_AI__AI_PROFILE_FILES__200043",
] as const;
export type FeatureFlagType = (typeof FeatureFlag)[number];

// Map to print feature flags
export const featuresMap: { [key in FeatureFlagType]: string } = {
  TRAINING_AI__APP__189322: "Training AI",
  ISEARCH__DATA__IMPORT__197186: "iSearch data import",
  CHAT_AI__ASSISTANTSAPI__199004: "ChatAI Assistants API RAG",
  CHAT_AI__AI_PROFILE_FILES__200043: "ChatAI AI Profile Files",
};

// Workspace ID Header key
export const WORKSPACE_ID_HEADER = "X-Workspace-Id";
