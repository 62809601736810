import { type AppEnumType } from "@shared-database/enums";

export const translations: {
  [key in AppEnumType]?: {
    [key: string]: {
      [key: string]: string;
    };
  };
} = {
  TRAINING_AI: {
    ZH: {
      "app.header": "Role-Play AI",
      "workspace.create": "创建工作区",
      "workspaces.all": "所有工作区",
      "topbar.workspace.label": "工作区",
      "topbar.runMode": "切换到测试模式",
      "project.settings": "项目设置",
      "scenarios.list.title": "场景",
      "scenario.new": "新建",
      "meta.data": "元数据",
      users: "用户",
      "scenario.create": "创建场景",
      "scenarios.header": "场景",
      "scenario.sessions.title": "会话",
      "sessions.table.id": "ID",
      "sessions.table.createdAt": "创建于",
      "sessions.table.scenario": "场景",
      "sessions.table.user": "用户",
      "sessions.table.status": "状态",
      "session.status.IN_PROGRESS": "进行中",
      "session.status.COMPLETED": "完成",
      "projectSettings.title": "项目设置",
      "projectSettings.publishState": "发布状态",
      "projectSettings.draft": "草稿",
      "projectSettings.published": "已发布",
      "projectSettings.projectName": "项目名称",
      "projectSettings.shortDescription": "简要描述",
      "projectSettings.longDescription": "详细描述",
      "projectSettings.cancel": "取消",
      "projectSettings.save": "保存",
      "metaData.title": "元数据",
      "metaData.tabs.indications": "指示",
      "metaData.tabs.products": "产品",
      "indications.title": "指示",
      "indications.add": "添加",
      "indications.table.name": "名称",
      "workspace.access": "项目访问",
      "workspace.access.availableTo": "可用于",
      "workspace.access.selectedLeoUsers": "已选择的LEO用户",
      "workspace.access.allLeoUsers": "整个LEO组织",
      "workspace.users": "项目用户",
      "workspace.addUser": "将用户添加到项目",
      "workspace.access.help":
        "只有LEO Pharma电子邮件地址的用户才能登录和访问此平台。根据需要使用下面的选项管理访问级别和用户角色。",
      "scenario.create.name": "名称*",
      "scenario.create.indication": "指示",
      "scenario.create.product": "产品",
      "scenario.create.cancel": "取消",
      "scenario.create.submit": "创建",
      "scenario.duplicate": "复制",
      "scenario.test": "测试场景",
      "scenario.delete": "删除",
      "scenario.tabs.settings": "设置",
      "scenario.tabs.aiRoles": "AI角色",
      "scenario.tabs.tests": "测试",
      "scenario.tabs.sessions": "会话",
      "scenario.duplicate.title": "复制场景",
      "scenario.duplicate.name": "名称*",
      "scenario.duplicate.indication": "指示",
      "scenario.duplicate.product": "产品",
      "scenario.duplicate.cancel": "取消",
      "scenario.duplicate.create": "创建",
      "session.run.home": "关闭",
      "session.run.end": "结束会话",
      "scenario.delete.title": "删除场景",
      "scenario.delete.description": "您确定要删除此场景吗？",
      "scenario.delete.cancel": "取消",
      "scenario.delete.confirm": "删除",
      "assistant.type.hcp": "HCP",
      "assistant.type.COACH": "教练",
      "assistant.type.MONITOR": "合规监督员",
      "assistant.type.EVALUATOR": "评估",
      "scenario.aiRoles.header": "AI角色",
      "scenario.settings.title": "标题",
      "scenario.settings.cancel": "取消",
      "scenario.settings.save": "保存",
      "scenario.settings.product": "产品",
      "scenario.settings.indication": "指示",
      "scenario.settings.description": "描述",
      "scenario.settings.successCriteria": "成功标准",
      "scenario.settings.focusArea": "焦点领域",
      "about.title": "关于Role-Play AI",
      "about.body": "Role-Play AI 是一个支持 AI 辅助培训课程的平台。",
      "about.demoRequest": "如需获取演示或上传项目，请联系",
      "adUserCSVImport.uploadCSV": "上传CSV",
      "session.run.description.label": "描述",
      "session.run.evaluation": "评估",
      "session.run.focusArea.label": "重点领域",
      "session.run.successCriteria.label": "成功标准",
      "workspace.email": "邮件",
      "Button to read more about a workspace": "阅读更多",
      "workspace.user": "用户",
    },
  },
};
