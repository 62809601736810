"use client";

import { type AITool } from "@app/_metadata";
import { Ident } from "@shared-components/global/ident";
import { Button } from "@shared-components/shadcn/button";
import { CardContent } from "@shared-components/shadcn/card";
import { api } from "@shared-server/api";
import { env } from "@shared/env.mjs";
import Link from "next/link";
import { useEffect, useState } from "react";

interface AboutContentProps {
  onClose?: () => void;
  aiTool?: AITool;
}

const defaultMeta: AITool = {
  slug: "/",
  tool: false,
  meta: {
    name: "LEO AI",
    description: "AI Tools for LEO Pharma by LEO Pharma",
    byline: "AI & Cloud Data Services",
    contactName: "Alex Schuleit",
    contactEmail: "alscu@leo-pharma.com",
  },
};

export const AboutContent = (props: AboutContentProps) => {
  const aiTool = props.aiTool || defaultMeta;
  const envQuery = api.utils.env.useQuery();
  const [showReadableVersion, setShowReadableVersion] = useState(true);

  useEffect(() => {
    if (envQuery.data && ["dev"].includes(envQuery.data)) {
      setShowReadableVersion(false);
    }
  }, [envQuery.data]);

  return (
    <>
      <div className="flex flex-col items-start rounded-t-md bg-leo-dark-green-600 p-6 text-white">
        <Ident invert aiTool={props.aiTool} />
        <div>
          <small
            onClick={() => setShowReadableVersion(!showReadableVersion)}
            className="mt-16 block"
          >
            {"Version: "}
            {showReadableVersion
              ? env.NEXT_PUBLIC_VERSION_READABLE
              : env.NEXT_PUBLIC_VERSION_HASH}
          </small>
        </div>
      </div>
      <CardContent className="p-6 pb-8 pt-4 font-semibold">
        <div className="flex flex-row items-center justify-between">
          <div>
            <p className="m-0 mt-1">{aiTool?.meta.byline}</p>
            <p className="m-0 text-sm">
              {`Contact: ${aiTool?.meta.contactName}, `}
              <Link
                className="text-link"
                href={`mailto:${aiTool?.meta.contactEmail}?subject=${aiTool?.meta.name}`}
              >
                {aiTool?.meta.contactEmail?.toLowerCase()}
              </Link>
            </p>
          </div>
          {props.onClose && (
            <Button onClick={() => props.onClose && props.onClose()}>
              {"Close"}
            </Button>
          )}
        </div>
      </CardContent>
    </>
  );
};
